<template>
    <div>
      <b-card border-variant="danger" v-if="errore_caricamento">
        <b-row class="match-height">
          <b-col
            md="8"
          >
            <h4 class="mt-0 mb-0 text-danger">Errore durante il caricamento delle informazioni iniziali</h4>
            <p>Ti preghiamo di segnalare il problema all'assistenza tecnica, grazie</p>
          </b-col>
          <b-col 
            md="4"
            class="text-center"
          >
            <b-button
            variant="primary"
            :to="{ name: 'home-agentscout' }"
            >
                <feather-icon
                    icon="CornerUpLeftIcon"
                    size="16"
                    class="mr-1"
                />Home
            </b-button>
          </b-col>
        </b-row>
          
      </b-card>
          
      <div class="text-center my-3" v-if="Caricato == false">
        <b-spinner  v-if="errore_caricamento == false"
            variant="primary"
            style="width: 3rem; height: 3rem;"
            label="loading"
        />
        <div class="pt-1 text-primary" style="display:block;">Caricamento</div>
      </div>
  
      <b-card bg-variant="Default" v-if="Caricato">
        <b-card-body class="px-0 py-0">
          <b-tabs pills>
            <b-tab active @click="onTabInCorso">
              <template #title>
                <feather-icon
                icon="AwardIcon"
                size="16"
                class="mr-0 mr-sm-50"
                />
                <span class="d-none d-sm-inline">In Corso</span>
              </template>

              <!--
              <b-row class="match-height">
                  <b-col
                      md="11"
                      cols="10" 
                      class="mb-2"
                  >
                      <b-input-group>
                      <b-input-group-prepend>
                          <b-form-select style="border-top-right-radius:0px; border-bottom-right-radius:0px;" v-model="campiRicerca.sezione" :options="options_ricerca" disabled-field="notEnabled"></b-form-select>
                      </b-input-group-prepend>
                          <b-form-input v-model="campiRicerca.nome"
                              placeholder="ricerca generale"
                              type="text" />
                      <b-input-group-append>
                          <b-button variant="outline-primary" @click.prevent="ricercaGenerale('Abbonamento')">
                          <feather-icon icon="SearchIcon" />
                          </b-button>
                      </b-input-group-append>
          
                      </b-input-group>
                  </b-col>
                  <b-col
                      md="1"
                      cols="2"
                      class="mb-2"
                  >
                      <b-button variant="outline-primary" class="px-0" @click.prevent="clickReset('Abbonamento')" block v-if="resetState != 'hidden'"><feather-icon icon="DeleteIcon" /></b-button>
                  </b-col>
                  
              </b-row>
              -->

              <b-button variant="outline-primary" class="px-1 btn-alto-dx" @click.prevent="clickReset('InCorso')" v-if="resetState != 'hidden'"><feather-icon icon="DeleteIcon" /></b-button>

              <!-- table -->
              <vue-good-table
                  mode="remote"
                  ref="table"
                  @on-page-change="onPageChange_InCorso"
                  @on-sort-change="onSortChange_InCorso"
                  @on-column-filter="onColumnFilter_InCorso"
                  @on-per-page-change="onPerPageChange_InCorso"
                  :totalRows="totalRecords"
                  :rows="rows"
                  :columns="columns"
                  :isLoading=isLoading
                  :search-options="{ 
                      enabled: true,
                      externalQuery: searchTerm }"
                  :pagination-options="{
                      enabled: true,
                      perPage:pageLength
                  }"
                  >
                  
                  <template slot="loadingContent">
                  <div class="text-center my-3">
                      <b-spinner
                          variant="primary"
                          style="width: 3rem; height: 3rem;"
                          label="loading"
                      />
                      <div class="d-block pt-1 text-primary">Caricamento</div>
                  </div>
                  </template>
                  
                  <div slot="emptystate"> <div class="text-center py-4"> Nessuna licenza presente</div> </div>
          
                  <template
                      slot="table-row"
                      slot-scope="props"
                  >

                      <!-- company_name -->
                      <div v-if="props.column.field === 'customer.billing.company_name'">
                      <a @click="$router.push({ name: 'crm-customers-show', params: { id_riga: props.row.customer.id }})">{{ props.row.customer.billing.company_name }}</a>   
                      </div>

                      <!-- Inizio -->
                      <div v-else-if="props.column.field === 'subscription'">
                          <span v-if="(props.formattedRow[props.column.field])">
                              {{ formattazioneOrario(props.row.subscription.created_at, 'DD/MM/YYYY') }}
                          </span>
                          <span v-else>
                              <span v-if="props.row.oneshot !== null">
                                  {{ formattazioneOrario(props.row.oneshot.started_at, 'DD/MM/YYYY') }}
                              </span>
                              <span v-else>
                                  ---
                              </span>
                          </span>
                      </div>

                      <!-- Inizio -->
                      <div v-else-if="props.column.field === 'oneshot'">
                          <span v-if="(props.formattedRow[props.column.field])">
                              {{ formattazioneOrario(props.row.oneshot.expires_at, 'DD/MM/YYYY') }}
                          </span>
                          <span v-else>
                              <span v-if="props.row.subscription !== null">
                                  {{ formattazioneOrario(props.row.subscription.ends_at, 'DD/MM/YYYY') }}
                              </span>
                              <span v-else>
                                  ---
                              </span>
                          </span> 
                      </div>

                      <!-- price -->
                      <span v-else-if="props.column.field === 'price'">
                      {{ prezzoFormattato(props.formattedRow[props.column.field]) }}
                      </span>

                      <span v-else-if="props.column.field === 'type'">
                      <b-badge :variant="statusVariant(props.row.type)">
                          {{ props.row.type }}
                      </b-badge>
                      </span>

                      <span v-else-if="props.column.field === 'status.name'">
                      <b-badge :variant="props.row.status.colore">
                          {{ props.row.status.name }}
                      </b-badge>
                      </span>

                      <!-- ricerca_fitizzia -->
                      <span v-else-if="props.column.field === 'ricerca_fitizzia'">
                          ---
                      </span>

                      <!-- azioni -->
                      <span v-else-if="props.column.field === 'azioni'">
                          <span v-if="props.row.subscription !== null">
                              <div class="text-nowrap">
                                  <feather-icon
                                  icon="MoreVerticalIcon"
                                  class="cursor-pointer text-secondary"
                                  v-b-tooltip.hover.v-primary
                                  title="Azioni"
                                  :id="`tabellautenti-riga-${props.row.id}-sidebar-azioni`"
                                  @click="sidebarAction(props.row.id, props.row.ref_code, props.row.customer.billing.company_name, props.row.status.name, props.row.status.colore, props.row.type, props.row.price, props.row.contract, props.row.user.stripe_id, props.row.subscription.ends_at)"
                                  />
                              </div>
                          </span>
                          <span v-else>
                              <div class="text-nowrap">
                                  <feather-icon
                                  icon="MoreVerticalIcon"
                                  class="cursor-pointer text-secondary"
                                  v-b-tooltip.hover.v-primary
                                  title="Azioni"
                                  :id="`tabellautenti-riga-${props.row.id}-sidebar-azioni`"
                                  @click="sidebarAction(props.row.id, props.row.ref_code, props.row.customer.billing.company_name, props.row.status.name, props.row.status.colore, props.row.type, props.row.price, props.row.contract, props.row.user.stripe_id, '')"
                                  />
                              </div>
                          </span>
                      </span>
          
                      <!-- tutte le altre colonne standard della tabella -->
                      <span v-else>
                      {{ props.formattedRow[props.column.field] }}
                      </span>
                  </template>
          
                  <!-- pagination -->
                  <template
                      slot="pagination-bottom"
                      slot-scope="props"
                  >
                      <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                          <span class="text-nowrap">
                          Visualizzazione da 1 a 
                          </span>
                          <b-form-select
                          v-model="pageLength"
                          :options="['10','25','50','100']"
                          class="mx-1"
                          @input="(value)=>props.perPageChanged({currentPerPage:value})"
                          />
                          <span class="text-nowrap "> di {{ props.total }} righe </span>
                      </div>
                      <div>
                          <b-pagination
                          :value="1"
                          :total-rows="props.total"
                          :per-page="pageLength"
                          first-number
                          last-number
                          align="right"
                          prev-class="prev-item"
                          next-class="next-item"
                          class="mt-1 mb-0"
                          @input="(value)=>props.pageChanged({currentPage:value})"
                          >
                          <template #prev-text>
                              <feather-icon
                              icon="ChevronLeftIcon"
                              size="18"
                              />
                          </template>
                          <template #next-text>
                              <feather-icon
                              icon="ChevronRightIcon"
                              size="18"
                              />
                          </template>
                          </b-pagination>
                      </div>
                      </div>
                  </template>
              </vue-good-table>

            </b-tab>

            <b-tab @click="onTabCompletate">
              <template #title>
                <feather-icon
                icon="CheckSquareIcon"
                size="16"
                class="mr-0 mr-sm-50"
                />
                <span class="d-none d-sm-inline">Completate</span>
              </template>

              <!--
              <b-row class="match-height">
                <b-col
                  md="11"
                  cols="10" 
                  class="mb-2"
                >
                
                  <b-input-group>
                    <b-input-group-prepend>
                      <b-form-select style="border-top-right-radius:0px; border-bottom-right-radius:0px;" v-model="campiRicerca_completate.sezione" :options="options_ricerca_completate" disabled-field="notEnabled"></b-form-select>
                    </b-input-group-prepend>
                      <b-form-input v-model="campiRicerca_completate.nome"
                          placeholder="ricerca generale"
                          type="text" />
                    <b-input-group-append>
                      <b-button variant="outline-primary" @click.prevent="ricercaGenerale('OneShot')">
                        <feather-icon icon="SearchIcon" />
                      </b-button>
                    </b-input-group-append>
        
                  </b-input-group>
                </b-col>
                <b-col
                  md="1"
                  cols="2"
                  class="mb-2"
                >
                  <b-button variant="outline-primary" class="px-0" @click.prevent="clickReset('OneShot')" block v-if="resetState_completate != 'hidden'"><feather-icon icon="DeleteIcon" /></b-button>
                </b-col>
                
              </b-row>
              -->

              <b-button variant="outline-primary" class="px-1 btn-alto-dx" @click.prevent="clickReset('Completate')" v-if="resetState_completate != 'hidden'"><feather-icon icon="DeleteIcon" /></b-button>
              
              <vue-good-table
                mode="remote"
                ref="table_Completate"
                @on-page-change="onPageChange_Completate"
                @on-sort-change="onSortChange_Completate"
                @on-column-filter="onColumnFilter_Completate"
                @on-per-page-change="onPerPageChange_Completate"
                :totalRows="totalRecords_completate"
                :columns="columns_completate"
                :rows="rows_completate"
                :isLoading=isLoading
                :search-options="{
                    enabled: true,
                    externalQuery: searchTerm_completate }"
                :pagination-options="{
                    enabled: true,
                    perPage:pageLength_completate
                }"
                >
              
                <template slot="loadingContent">
                  <div class="text-center my-3">
                      <b-spinner
                        variant="primary"
                        style="width: 3rem; height: 3rem;"
                        label="loading"
                      />
                      <div class="d-block pt-1 text-primary">Caricamento</div>
                  </div>
                </template>
                
                <div slot="emptystate"> <div class="text-center py-4"> Nessuna licenza presente</div> </div>
                
                <template
                    slot="table-row"
                    slot-scope="props"
                >

                  <!-- company_name -->
                  <div v-if="props.column.field === 'customer.billing.company_name'">
                  <a @click="$router.push({ name: 'crm-customers-show', params: { id_riga: props.row.customer.id }})">{{ props.row.customer.billing.company_name }}</a>   
                  </div>

                  <!-- Inizio -->
                  <div v-else-if="props.column.field === 'subscription'">
                      <span v-if="(props.formattedRow[props.column.field])">
                          {{ formattazioneOrario(props.row.subscription.created_at, 'DD/MM/YYYY') }}
                      </span>
                      <span v-else>
                          <span v-if="props.row.oneshot !== null">
                              {{ formattazioneOrario(props.row.oneshot.started_at, 'DD/MM/YYYY') }}
                          </span>
                          <span v-else>
                              ---
                          </span>
                      </span>
                  </div>

                  <!-- Inizio -->
                  <div v-else-if="props.column.field === 'oneshot'">
                      <span v-if="(props.formattedRow[props.column.field])">
                          {{ formattazioneOrario(props.row.oneshot.expires_at, 'DD/MM/YYYY') }}
                      </span>
                      <span v-else>
                          <span v-if="props.row.subscription !== null">
                              {{ formattazioneOrario(props.row.subscription.ends_at, 'DD/MM/YYYY') }}
                          </span>
                          <span v-else>
                              ---
                          </span>
                      </span> 
                  </div>

                  <!-- price -->
                  <span v-else-if="props.column.field === 'price'">
                  {{ prezzoFormattato(props.formattedRow[props.column.field]) }}
                  </span>

                  <span v-else-if="props.column.field === 'type'">
                  <b-badge :variant="statusVariant(props.row.type)">
                      {{ props.row.type }}
                  </b-badge>
                  </span>

                  <span v-else-if="props.column.field === 'status.name'">
                  <b-badge :variant="props.row.status.colore">
                      {{ props.row.status.name }}
                  </b-badge>
                  </span>

                  <!-- ricerca_fitizzia -->
                  <span v-else-if="props.column.field === 'ricerca_fitizzia'">
                      ---
                  </span>

                  <!-- azioni -->
                  <span v-else-if="props.column.field === 'azioni'">
                      <span v-if="props.row.subscription !== null">
                          <div class="text-nowrap">
                              <feather-icon
                              icon="MoreVerticalIcon"
                              class="cursor-pointer text-secondary"
                              v-b-tooltip.hover.v-primary
                              title="Azioni"
                              :id="`tabellautenti-riga-${props.row.id}-sidebar-azioni`"
                              @click="sidebarAction(props.row.id, props.row.ref_code, props.row.customer.billing.company_name, props.row.status.name, props.row.status.colore, props.row.type, props.row.price, props.row.contract, props.row.user.stripe_id, props.row.subscription.ends_at)"
                              />
                          </div>
                      </span>
                      <span v-else>
                          <div class="text-nowrap">
                              <feather-icon
                              icon="MoreVerticalIcon"
                              class="cursor-pointer text-secondary"
                              v-b-tooltip.hover.v-primary
                              title="Azioni"
                              :id="`tabellautenti-riga-${props.row.id}-sidebar-azioni`"
                              @click="sidebarAction(props.row.id, props.row.ref_code, props.row.customer.billing.company_name, props.row.status.name, props.row.status.colore, props.row.type, props.row.price, props.row.contract, props.row.user.stripe_id, '')"
                              />
                          </div>
                      </span>
                  </span>
      
                  <!-- tutte le altre colonne standard della tabella -->
                  <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                  </span>       
                </template>

                <!-- pagination -->
                <template
                    slot="pagination-bottom"
                    slot-scope="props"
                >
                    <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap">
                        Visualizzazione da 1 a 
                        </span>
                        <b-form-select
                        v-model="pageLength_completate"
                        :options="['3','5','10']"
                        class="mx-1"
                        @input="(value)=>props.perPageChanged({currentPerPage:value})"
                        />
                        <span class="text-nowrap "> di {{ props.total }} righe </span>
                    </div>
                    <div>
                        <b-pagination
                        :value="1"
                        :total-rows="props.total"
                        :per-page="pageLength_completate"
                        first-number
                        last-number
                        align="right"
                        prev-class="prev-item"
                        next-class="next-item"
                        class="mt-1 mb-0"
                        @input="(value)=>props.pageChanged({currentPage:value})"
                        >
                        <template #prev-text>
                            <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                            />
                        </template>
                        <template #next-text>
                            <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                            />
                        </template>
                        </b-pagination>
                    </div>
                    </div>
                </template>
                </vue-good-table>

            </b-tab>

          </b-tabs>
              
        </b-card-body>
      </b-card>
  
      <b-sidebar
        ref="sidebar_azioni"
        id="sidebar-right"
        bg-variant="white"
        :title="infoSideBar.titolo"
        right
        backdrop
        shadow
      >
        <div>
          <div class="py-0 px-2">
            
            <div class="divider">
                <div class="divider-text text-primary fw-bolder">DETTAGLI</div>
            </div>
            <b-row cols="1">
              <b-col class="riga_sidebar">
                <span class="font-weight-bold spacer-dettagli">Cliente:</span> {{ infoSideBar.customer_name }}
              </b-col>
              <b-col class="riga_sidebar">
                <span class="font-weight-bold spacer-dettagli">Tipo:</span> <b-badge :variant="statusVariant(infoSideBar.type)">{{ infoSideBar.type }}</b-badge>
              </b-col>
              <b-col class="riga_sidebar">
                <span class="font-weight-bold spacer-dettagli" style="margin-right:5px;">Stato:</span> 
                <b-badge :variant="infoSideBar.status_colore">{{ infoSideBar.status_name }}</b-badge>
              </b-col>
              <b-col class="riga_sidebar">
                <span class="font-weight-bold spacer-dettagli">Prezzo:</span> {{ prezzoFormattato(infoSideBar.price) }}
              </b-col>
              <b-col class="riga_sidebar">
                <span class="font-weight-bold spacer-dettagli">Stripe:</span> <a :href="infoSideBar.link_stripe" target="_blank">Apri scheda</a>
              </b-col>
            </b-row>

            <div v-if="((infoSideBar.contract_id != '') && (infoSideBar.contract_accepted_at != ''))">
              <div class="divider">
                  <div class="divider-text text-primary fw-bolder">CONTRATTO</div>
              </div>
              <b-row cols="1">
                <b-col>
                  <span class="font-weight-bold spacer-dettagli">ID:</span>  {{ infoSideBar.contract_id }}
                </b-col>
                <b-col>
                  <span class="font-weight-bold spacer-dettagli" style="padding-right: 5px;">Stato:</span>  
                  <b-badge variant="light-success" v-if="infoSideBar.contract_accepted_at != null">Firmato</b-badge> 
                  <b-badge variant="light-danger" v-else>Da Firmare</b-badge>
                </b-col>
                <b-col v-if="infoSideBar.type == 'Abbonamento'">
                  <span class="font-weight-bold spacer-dettagli">Rinnovo:</span>  {{ infoSideBar.data_rinnovo_tacito }}
                </b-col>
              </b-row>
            </div>

            <div class="divider">
              <div class="divider-text text-primary fw-bolder mt-2">AZIONI</div>
            </div>
            
            <b-row>
              <b-col md="12" v-if="infoSideBar.status_name == 'Creata'">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-danger" block
                  class="mb-1"
                  @click="cambioStatoLicenza(infoSideBar.type,infoSideBar.id,60,'error','Sei sicuro di voler cancellare la licenza?')"
                >
                  <feather-icon icon="Trash2Icon" /> Cancella
                </b-button>
              </b-col>
              <b-col md="12" lg="6" v-if="(infoSideBar.type == 'OneShot') && (infoSideBar.status_name == 'Attiva')">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-danger" block
                  class="mb-1"
                  @click="cambioStatoLicenza(infoSideBar.type,infoSideBar.id,60,'error','Sei sicuro di voler revocare la licenza?')"
                >
                  <feather-icon icon="Trash2Icon" /> Revoca
                </b-button>
              </b-col>

              <b-col md="12" lg="6" v-if="(infoSideBar.type == 'Abbonamento') && ((infoSideBar.status_name == 'Confermata') || (infoSideBar.status_name == 'Accettata'))">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-danger" block
                  class="mb-1"
                  @click="cambioStatoLicenza(infoSideBar.type,infoSideBar.id,60,'error','Sei sicuro di voler revocare la licenza?')"
                >
                  <feather-icon icon="Trash2Icon" /> Revoca
                </b-button>
              </b-col>
              
              <b-col md="12" lg="6" v-if="(infoSideBar.type == 'Abbonamento') && (infoSideBar.status_name == 'Attiva')">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-info" block
                  class="mb-1"
                  @click="cambioStatoLicenza(infoSideBar.type,infoSideBar.id,40,'info','Sei sicuro di voler sospendere la licenza?')"
                >
                    <feather-icon icon="RefreshCwIcon" /> Sospendi
                </b-button>
              </b-col>
              <b-col md="12" lg="6" v-if="(infoSideBar.type == 'Abbonamento') && (infoSideBar.status_name == 'Attiva')">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-warning" block
                  class="mb-1"
                  @click="cambioStatoLicenza(infoSideBar.type,infoSideBar.id,70,'error','Sei sicuro di voler mettere in recesso la licenza?')"
                >
                  <feather-icon icon="RefreshCwIcon" /> In Recesso
                </b-button>
              </b-col>
              <b-col md="12" lg="6" v-if="(infoSideBar.type == 'Abbonamento') && (infoSideBar.status_name == 'Attiva')">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-danger" block
                  class="mb-1"
                  @click="cambioStatoLicenza(infoSideBar.type,infoSideBar.id,60,'error','Sei sicuro di voler revocare la licenza?')"
                >
                  <feather-icon icon="Trash2Icon" /> Revoca
                </b-button>
              </b-col>

              <b-col md="12" lg="6" v-if="(infoSideBar.type == 'Abbonamento') && (infoSideBar.status_name == 'Sospesa')">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-warning" block
                  class="mb-1"
                  @click="cambioStatoLicenza(infoSideBar.type,infoSideBar.id,30,'warning','Sei sicuro di voler ri-attivare la licenza?')"
                >
                    <feather-icon icon="RefreshCwIcon" /> Riattiva
                </b-button>
              </b-col>
              <b-col md="12" lg="6" v-if="(infoSideBar.type == 'Abbonamento') && (infoSideBar.status_name == 'Sospesa')">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-danger" block
                  class="mb-1"
                  @click="cambioStatoLicenza(infoSideBar.type,infoSideBar.id,60,'error','Sei sicuro di voler revocare la licenza?')"
                >
                  <feather-icon icon="Trash2Icon" /> Revoca
                </b-button>
              </b-col>
              
              <b-col md="12" lg="12" v-if="(infoSideBar.status_name == 'In recesso') || (infoSideBar.status_name == 'Revocata') || (infoSideBar.status_name == 'Scaduta') || (infoSideBar.status_name == 'Accettata')">
                <b-button
                  v-if="infoSideBar.contract_url_full_file_pdf"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary" block
                  class="mb-1"
                  :href="infoSideBar.contract_url_full_file_pdf" target="_blank"
                ><feather-icon icon="DownloadIcon" /> Scarica Contratto</b-button>
              </b-col>
              <b-col md="12" lg="6" v-else>
                <b-button
                  v-if="infoSideBar.contract_url_full_file_pdf"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary" block
                  class="mb-1"
                  :href="infoSideBar.contract_url_full_file_pdf" target="_blank"
                ><feather-icon icon="DownloadIcon" /> Scarica Contratto</b-button>
              </b-col>
            
            </b-row>

          </div>
  
        </div>
      </b-sidebar>

      <b-modal
        ref="openapi-modal"
        centered
        hide-footer
      >
        <h3 class="text-success">Licenza sospesa correttamente!</h3>
        <p>Vuoi impostare un'attività nel calendario per ricordati di riattivare questa licenza?</p>
        <newactivity field_tipo="Customer" :field_id="openapiModal.customer_id" :field_nome_relazione="openapiModal.customer_name"></newactivity>
      </b-modal>

    </div>
  </template>
  
  <script>
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import {
    BTabs, BTab, BCard, BCardBody, BCardText, BRow, BCol, BBadge, BPagination, BSpinner, BFormGroup, BInputGroup, BFormInput, BInputGroupPrepend, BInputGroupAppend, BDropdownDivider, BFormSelect, BDropdown, BDropdownItem, BButton, BSidebar, VBToggle, VBTooltip, BModal
  } from 'bootstrap-vue'
  import { VueGoodTable } from 'vue-good-table'
  import Ripple from 'vue-ripple-directive'
  import moment from 'moment'
  import { prezzoFormattato } from '@core/utils/funzioniGlobali' 
import newactivity from '@core/components/newactivity/newactivity.vue' 
  
  export default {
    components: {
      BTabs, 
      BTab, 
      BCardCode,
      VueGoodTable,
      BTabs,
      BTab,
      BCard, 
      BCardBody, 
      BCardText,
      BRow, 
      BCol, 
      BBadge,
      BPagination,
      BSpinner,
      BInputGroup,
      BFormGroup,
      BFormInput,
      BInputGroupPrepend,
      BInputGroupAppend,
      BDropdownDivider,
      BFormSelect,
      BDropdown,
      BDropdownItem,
      BSidebar,
      BButton,
      BModal, 

      newactivity, 
    },
    setup() {
        return {
            prezzoFormattato,
        }
    },
    directives: {
      'b-toggle': VBToggle,
      'b-tooltip': VBTooltip,
      Ripple,
    },
    data() {
      return {
        isLoading: true,
        emptystate: true, 
        pageLength: 10,
        dir: false,
        columns: [
          {
            label: 'Licenza',
            field: 'ref_code',
            width: '100px',
            filterOptions: {
              enabled: true,
              placeholder: 'Filtra',
            },
          },
          {
            label: 'Cliente',
            field: 'customer.billing.company_name',
            filterOptions: {
              enabled: true,
              placeholder: 'Filtra',
            },
          },
          {
            label: 'Tipo',
            field: 'type',
            width: '130px',
            filterOptions: {
              enabled: true,
              placeholder: 'Filtra',
              filterDropdownItems: [
                { text: "Abbonamento", value: "Abbonamento" },
                { text: "OneShot", value: "OneShot" }
              ],
            },
          },
          {
            label: 'Stato',
            field: 'status.name',
            width: '130px',
            filterOptions: {
              enabled: false,
              placeholder: 'Filtra',
            },
          },
          {
            label: 'Prezzo',
            field: 'price',
            width: '120px',
            filterOptions: {
              enabled: true,
              placeholder: 'Filtra',
            },
          },
          {
            label: 'Inizio',
            field: 'subscription',
            width: '110px',
            filterOptions: {
              enabled: false,
              placeholder: 'Filtra',
            },
            sortable: false,
          },
          {
            label: 'Fine',
            field: 'oneshot',
            width: '110px',
            filterOptions: {
              enabled: false,
              placeholder: 'Filtra',
            },
            sortable: false,
          },
  
          {
            label: '',
            field: 'azioni',
            sortable: false,
            tdClass: 'text-center',
          },
        ],
        rows: [],
        searchTerm: '',
        resetState: 'hidden',
        totalRecords: 0,
        serverParams: {
          columnFilters: {
            /*
            "customer_id": '1',
            "status.id": {"operator": "=", "value": 20 }
            */
            // "type":"Abbonamento", 
            "status_id": {"operator":"in", "value":["10","25","30","40","70"] }, 
            "deleted_at": {"operator": "=", "value": null }  
          },
          sort: [
              {
              field: 'id',
              type: 'desc'
              }
          ],
          page: 1, 
          perPage: 10
        },
        startServerParams: {
          columnFilters: {
            /*
            "customer_id": '1',
            "status.id": {"operator": "=", "value": 20 }
            */
            // "type":"Abbonamento",
            "status_id": {"operator":"in", "value":["10","25","30","40","70"] }, 
            "deleted_at": {"operator": "=", "value": null }
          },
          sort: [
              {
              field: 'id',
              type: 'desc'
              }
          ],
          page: 1, 
          perPage: 10
        },
        options_ricerca: [
          { value: null, text: 'seleziona...' },
          { value: 'ref_code', text: 'Licenza' },
          { value: 'customer.billing.company_name', text: 'Cliente' },
          { value: 'subscription.created_at', text: 'Data d\'inizio' },
          { value: 'status.name', text: 'Stato' }
        ],
        campiRicerca: {
          nome: '',
          sezione: null,
        },

        /* goodtable per Completate */
        show_loader_completate: true, 
        pageLength_completate: 10,
        totalRecords_completate: 0,
        columns_completate: [
          {
            label: 'Licenza',
            field: 'ref_code',
            width: '100px',
            filterOptions: {
              enabled: true,
              placeholder: 'Filtra',
            },
          },
          {
            label: 'Cliente',
            field: 'customer.billing.company_name',
            filterOptions: {
              enabled: true,
              placeholder: 'Filtra',
            },
          },
          {
            label: 'Tipo',
            field: 'type',
            width: '130px',
            filterOptions: {
              enabled: true,
              placeholder: 'Filtra',
              filterDropdownItems: [
                { text: "Abbonamento", value: "Abbonamento" },
                { text: "OneShot", value: "OneShot" }
              ],
            },
          },
          {
            label: 'Stato',
            field: 'status.name',
            width: '130px',
            filterOptions: {
              enabled: false,
              placeholder: 'Filtra',
            },
          },
          {
            label: 'Prezzo',
            field: 'price',
            width: '120px',
            filterOptions: {
              enabled: true,
              placeholder: 'Filtra',
            },
          },
          {
            label: 'Inizio',
            field: 'subscription',
            width: '110px',
            filterOptions: {
              enabled: false,
              placeholder: 'Filtra',
            },
            sortable: false,
          },
          {
            label: 'Fine',
            field: 'oneshot',
            width: '110px',
            filterOptions: {
              enabled: false,
              placeholder: 'Filtra',
            },
            sortable: false,
          },

          {
            label: '',
            field: 'azioni',
            sortable: false,
            tdClass: 'text-center',
          },
        ],
        rows_completate: [],
        
        searchTerm_completate: '',
        resetState_completate: 'hidden',
        serverParams_completate: {
          columnFilters: {
            "status_id": {"operator":"in", "value":["50","60"] }, 
            "deleted_at": {"operator": "=", "value": null }
          },
          sort: [
              {
              field: 'id',
              type: 'desc'
              }
          ],
          page: 1, 
          perPage: 10
        },
        startServerParams_completate: {
          columnFilters: {
            "status_id": {"operator":"in", "value":["50","60"] }, 
            "deleted_at": {"operator": "=", "value": null }
          },
          sort: [
              {
              field: 'id',
              type: 'desc'
              }
          ],
          page: 1, 
          perPage: 10
        },
        options_ricerca_completate: [
          { value: null, text: 'seleziona...' },
          { value: 'ref_code', text: 'Licenza' },
          { value: 'customer.billing.company_name', text: 'Cliente' },
          { value: 'oneshot.started_at', text: 'Data d\'inizio' },
          { value: 'oneshot.expires_at', text: 'Data di fine' },
          { value: 'status.name', text: 'Stato' }
        ],
        campiRicerca_completate: {
          nome: '',
          sezione: null,
        },
        /* goodtable per Completate */

        infoSideBar: {
          id: '', 
          ref_code: '', 
          customer_name: '',
          status_name: '', 
          status_colore: '', 
          type: '', 
          price: '', 
          contract_id: '', 
          contract_accepted_at: '',
          contract_url_full_file_pdf: '',
          data_rinnovo_tacito: '',
        },
        listSourceOption: [ ],
        listStatusOption: [ ],
        Caricato: false,
        errore_caricamento: false,
        windowWidth: 0,

        openapiModal: {
          customer_id: '',
          customer_name: '',
        },

      }
    },
  computed: {
    statusVariant() {
      const statusColor = {
        'Abbonamento' : 'light-info',
        'OneShot' : 'light-secondary',
      }

      return status => statusColor[status]
    },
  },
    mounted() {
        //in caso di resize del browser -> ricalcolo della larghezza della finestra
        window.onresize = () => {
            this.checkWindowsSizeAndResponsiveGoodTable();
        }
    },
    created() {
        this.checkWindowsSizeAndResponsiveGoodTable();

        //caricamento dei dati
        this.loadItems('InCorso');

        this.Caricato = true;
        this.errore_caricamento = false;
    },
    methods: {
      onTabInCorso() {
        /* caricare dati delle licenze InCorso */
        this.loadItems("InCorso");
      },
      onTabCompletate(){
        /* caricare dati delle Completate */
        this.loadItems("Completate");
      },

      checkWindowsSizeAndResponsiveGoodTable(){
        this.windowWidth = window.innerWidth
  
        if(window.innerWidth <1000){
          this.$set(this.columns[3], 'hidden', true)
          this.$set(this.columns[4], 'hidden', true)
        } else if(window.innerWidth <1200){
          this.$set(this.columns[3], 'hidden', false)
          this.$set(this.columns[4], 'hidden', false)
        } else if(window.innerWidth <1400){
          this.$set(this.columns[3], 'hidden', false)
          this.$set(this.columns[4], 'hidden', false)
        } else {
          //tutte le colonne visualizzate
          this.$set(this.columns[3], 'hidden', false)
          this.$set(this.columns[4], 'hidden', false)
        }
      },
      ricercaGenerale(tipo){
        if(
          (this.campiRicerca.sezione !== null) && (this.campiRicerca.sezione !== 'undefined')
          ||
          (this.campiRicerca_completate.sezione !== null) && (this.campiRicerca_completate.sezione !== 'undefined')
        ){
          console.log("ok ricerca valida -> in: "+tipo)
          
          let valore_ricerca = '';

          if(tipo == 'Abbonamento'){
            //conversione data nel formmato per la ricerca
            if(this.campiRicerca.sezione == 'subscription.created_at'){
              console.log("subscription.created_at PRIMA -> "+this.campiRicerca.nome)

              valore_ricerca = moment(this.campiRicerca.nome, ["DD/MM/YYYY"]).format("YYYY-MM-DD");

              console.log("subscription.created_at DOPO -> "+valore_ricerca)
            } else {
              //altri campi che NON hanno bisogno di pre-formattazione
              valore_ricerca = this.campiRicerca.nome;
            }
    
            //aggiorna parametri con la stessa funzione del filtro colonna ;-)
            this.updateParams(tipo, {
              columnFilters: {
                type: tipo,
                [this.campiRicerca.sezione]: valore_ricerca
              },
            });
          } else {
            //conversione data nel formmato per la ricerca
            if(this.campiRicerca_completate.sezione == 'oneshot.started_at'){
              valore_ricerca = moment(this.campiRicerca_completate.nome, ["DD/MM/YYYY"]).format("YYYY-MM-DD");
            } else if(this.campiRicerca_completate.sezione == 'oneshot.expires_at'){
              valore_ricerca = moment(this.campiRicerca_completate.nome, ["DD/MM/YYYY"]).format("YYYY-MM-DD");
            } else {
              //altri campi che NON hanno bisogno di pre-formattazione
              valore_ricerca = this.campiRicerca_completate.nome;
            }
    
            //aggiorna parametri con la stessa funzione del filtro colonna ;-)
            this.updateParams(tipo, {
              columnFilters: {
                type: tipo,
                [this.campiRicerca_completate.sezione]: valore_ricerca
              },
            });
          }
  
          //forza reset filtro colonne
          this.$refs.table.reset();
  
          this.loadItems(tipo);
  
          //attiva bottone "reset"
          this.resetState = 'attiva';
        }
      },
      clickReset(tipo_licenza){
        console.log("clickReset -> "+tipo_licenza);
  
        if(tipo_licenza == 'InCorso'){
          //forza reset filtro colonne
          this.$refs.table.reset();
    
          //forza reset parametri iniziali della tabella
          this.updateParams(tipo_licenza, this.startServerParams);
          this.loadItems(tipo_licenza);
    
          //reset campi ricerca
          this.campiRicerca.nome = '';
          this.campiRicerca.sezione = null;
          //disattiva bottone "reset"
          this.resetState = 'hidden';
        } else {
          //forza reset filtro colonne Completate
          this.$refs.table_Completate.reset();
    
          //forza reset parametri iniziali della tabella
          this.updateParams(tipo_licenza, this.startServerParams_completate);
          this.loadItems(tipo_licenza);
    
          //reset campi ricerca
          this.campiRicerca_completate.nome = '';
          this.campiRicerca_completate.sezione = null;
          //disattiva bottone "reset"
          this.resetState_completate = 'hidden';
        }
        
      },
      advanceFilter_STOP(val) {
        //this.searchTerm = val
  
        this.updateParams(val);
        this.loadItems();
      },
      sidebarAction(id, ref_code, customer_name, status_name, status_colore, type, price, contract_array, stripe_id, data_ends_at) {
        let contract_id_ok = ''
        let contract_accepted_at_ok = ''
        let contract_url_full_file_pdf = ''

        //in caso di licenza diversa da stato "Creata"
        if(contract_array !== null){
          contract_id_ok = contract_array.id
          contract_accepted_at_ok = contract_array.accepted_at

          if(contract_array.file_pdf !== null){
            contract_url_full_file_pdf = contract_array.dominio+'/download-contratto/file/'+ref_code;
          }
        }

        let data_rinnovo_tacito_calcolata = '';

        if(type == 'Abbonamento'){
            // Calcolo rinnovo tacito per gli Abbonamenti su Stripe (subscription) => dipende delle impostazioni Stripe
            // https://dashboard.stripe.com/settings/billing/automatic
            // su dev cambiato da 7 a 3 giorni
            data_rinnovo_tacito_calcolata+= moment(data_ends_at).subtract(7, 'days').format('DD/MM/YYYY');
        }

        this.infoSideBar = { id: id,
          titolo: 'Licenza: '+ref_code,
          id: id,
          customer_name: customer_name,
          status_name: status_name, 
          status_colore: status_colore,
          type: type,
          price: price,
          contract_id: contract_id_ok,
          contract_accepted_at: contract_accepted_at_ok, 
          contract_url_full_file_pdf: contract_url_full_file_pdf,
          link_stripe: 'https://dashboard.stripe.com/test/customers/'+stripe_id, 
          data_rinnovo_tacito: data_rinnovo_tacito_calcolata
        };
  
        this.$root.$emit('bv::toggle::collapse', 'sidebar-right')
      },
      
      updateParams(tipo_licenza, newProps) {
        /*
        console.log("GENERALE updateParams per ..... "+tipo_licenza+"...............")
        console.log(newProps)
        console.log("newProps.columnFilters.type -> "+tipo_licenza)
        console.log("Precedente this.serverParams")
        console.log(this.serverParams)
        */
        
        if(tipo_licenza == 'InCorso'){

          if (typeof newProps.columnFilters !== 'undefined'){
            //console.log("QUI QUI QUI -------");

            //iniettare sempre il filtro per lo status
            newProps.columnFilters.deleted_at = {}
            newProps.columnFilters.deleted_at.operator = '='
            newProps.columnFilters.deleted_at.value = null

            //iniettare sempre il filtro per lo status_id della licenza
            //"status_id": {"operator":"in", "value":["10","25","30","40","70"] }, 
            newProps.columnFilters.status_id = {}
            newProps.columnFilters.status_id.operator = 'in'
            newProps.columnFilters.status_id.value = ["10","25","30","40","70"]
          }

          //attiva bottone "reset" filtro o ricerca
          this.resetState = 'attiva';

          this.serverParams = Object.assign({}, this.serverParams, newProps);
          
        } else {
          
          if (typeof newProps.columnFilters !== 'undefined'){
            //console.log("QUI QUI QUI -------");

            //iniettare sempre il filtro per lo status
            newProps.columnFilters.deleted_at = {}
            newProps.columnFilters.deleted_at.operator = '='
            newProps.columnFilters.deleted_at.value = null

            //iniettare sempre il filtro per lo status_id della licenza
            //"status_id": {"operator":"in", "value":["50","60"] }, 
            newProps.columnFilters.status_id = {}
            newProps.columnFilters.status_id.operator = 'in'
            newProps.columnFilters.status_id.value = ["50","60"]
          }

          //attiva bottone "reset" filtro o ricerca
          this.resetState_completate = 'attiva';

          this.serverParams_completate = Object.assign({}, this.serverParams_completate, newProps);
        }
      },
      
      onPageChange_InCorso(params) {
        this.updateParams('InCorso', {page: params.currentPage});
        this.loadItems('InCorso');
      },
      onPageChange_Completate(params) {
        this.updateParams('Completate', {page: params.currentPage});
        this.loadItems('Completate');
      },
  
      onPerPageChange_InCorso(params) {
        this.updateParams('InCorso', {perPage: params.currentPerPage});
        this.loadItems('InCorso');
      },
      onPerPageChange_Completate(params) {
        this.updateParams('Completate', {perPage: params.currentPerPage});
        this.loadItems('Completate');
      },
  
      onSortChange_InCorso(params) {
        //console.log('cambio ordinamento')
        //console.log(params)
  
        let field_ricerca = '';
  
        //colonne con relazione
        if(params[0].field == 'source.text'){
          field_ricerca = 'source.name';
        } else {
          field_ricerca = params[0].field;
        }
  
        this.updateParams('InCorso', {
          sort: [{
            type: params[0].type,
            field: field_ricerca,
          }],
        });
        this.loadItems('InCorso');
      },

      onSortChange_Completate(params) {
        let field_ricerca = '';
  
        //colonne con relazione
        if(params[0].field == 'source.text'){
          field_ricerca = 'source.name';
        } else {
          field_ricerca = params[0].field;
        }
  
        this.updateParams('Completate', {
          sort: [{
            type: params[0].type,
            field: field_ricerca,
          }],
        });
        this.loadItems('Completate');
      },
      
      onColumnFilter_InCorso(params) {
        console.log("in ->>>> onColumnFilter_InCorso")
        console.log(params)
        
        this.updateParams('InCorso', params);
        this.loadItems('InCorso');
      },

      onColumnFilter_Completate(params) {
        console.log("in ->>>> onColumnFilter_Completate")
        console.log(params)
        
        this.updateParams('Completate', params);
        this.loadItems('Completate');
      },
  
      loadItems(tipo = 'InCorso') {
        this.isLoading = true;

        console.log("tipo -> "+tipo);
        console.log("this.serverParams ---------------")
        console.log(this.serverParams)
  
        //cambio tipo di licenza richiesta
        if(tipo == 'InCorso'){
          //this.serverParams.columnFilters.type = "Abbonamento";

          this.$http.get('api/crm/license/list', {
            params: this.serverParams 
            }).then(response => { 
                if(response.data.statusCode == 200){
                  //ok
                  this.totalRecords = response.data.reply.meta.total;
                  this.rows = response.data.reply.data;
                  
                } else if(response.data.statusCode == 422){
                  //errori con testo di notifica ok dall'api
                  this.$swal({
                    title: 'Errore',
                    text: 'codice errore: '+response.data.reply,
                    icon: 'error',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                      textButton: 'Chiudi',
                    },
                    buttonsStyling: false,
                  })
                } else {
                  //tutto gli altri possibili errori
                  this.$swal({
                    title: 'Errore Generico',
                    text: 'codice errore: '+response.data.statusCode,
                    icon: 'error',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                      textButton: 'Chiudi',
                    },
                    buttonsStyling: false,
                  })
                }
                
                //rimuovi il loading dopo il caricamento dei dati nella tabella
                this.isLoading = false;
            
            })
        } else {
          //one shot
          //this.serverParams_completate.columnFilters.type = "OneShot";

          this.$http.get('api/crm/license/list', {
            params: this.serverParams_completate 
            }).then(response => { 
                if(response.data.statusCode == 200){
                  //ok
                  this.totalRecords_completate = response.data.reply.meta.total;
                  this.rows_completate = response.data.reply.data;
                  
                } else if(response.data.statusCode == 422){
                  //errori con testo di notifica ok dall'api
                  this.$swal({
                    title: 'Errore',
                    text: 'codice errore: '+response.data.reply,
                    icon: 'error',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                      textButton: 'Chiudi',
                    },
                    buttonsStyling: false,
                  })
                } else {
                  //tutto gli altri possibili errori
                  this.$swal({
                    title: 'Errore Generico',
                    text: 'codice errore: '+response.data.statusCode,
                    icon: 'error',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                      textButton: 'Chiudi',
                    },
                    buttonsStyling: false,
                  })
                }
                
                //rimuovi il loading dopo il caricamento dei dati nella tabella
                this.isLoading = false;
            
            })
        }
        
  
      },

      cambioStatoLicenza(tipo_licenza,id_licenza,id_nuovo_stato,style_color,messaggio_conferma){
        console.log("cambioStatoLicenza ++++++\nid_licenza -> "+id_licenza+"\nid_nuovo_stato -> "+id_nuovo_stato)

        let style_button = ''
        let route_api = ''

        if(style_color == 'error'){
          style_button = 'btn btn-outline-danger'
        } else if(style_color == 'primary'){
          style_button = 'btn btn-outline-success'
        } else {
          style_button = 'btn btn-outline-'+style_color
        }

        if(id_nuovo_stato == '40'){
          //verso la "sospensione"
          route_api = 'api/crm/license/pause/'+id_licenza;
        } else if(id_nuovo_stato == '30'){
          //verso la "ri-attivazione"
          route_api = 'api/crm/license/resume/'+id_licenza;
        }  else if(id_nuovo_stato == '70'){
          //verso la "in recesso"
          route_api = 'api/crm/license/withdrawal/'+id_licenza;
        } else {
          //tutto il resto
          route_api = 'api/crm/license/stop/'+id_licenza+'/'+id_nuovo_stato
        }

        this.$swal({
          title: 'Sei sicuro di voler procedere?',
          text: messaggio_conferma,
          icon: style_color,
          showCancelButton: true,
          confirmButtonText: 'SI, Procedi',
          cancelButtonText: 'Annulla operazione',
          customClass: {
            confirmButton: style_button,
            cancelButton: 'btn btn-primary ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.$http.post(route_api)
            .then(response => { 
                /*
                console.log("risposta server --------")
                console.log(response.data.reply.licenseData)
                console.log("risposta customer --------")
                console.log(response.data.reply.licenseData.customer.id)
                console.log("risposta customer company_name --------")
                console.log(response.data.reply.licenseData.customer.billing.company_name)
                */

                //console.log("customer_id: "+response.data.reply.licenceData.customer.id)
                //console.log("customer_name: "+response.data.reply.licenceData.customer.billing.company_name)

                if(response.data.statusCode===200){
                  if(id_nuovo_stato == 40){
                    //finestra personalizzata per consentire di aggiungere una nota in calendario (se si vuole)
                    this.openapiModal = {
                      customer_id: response.data.reply.licenseData.customer.id,
                      customer_name: response.data.reply.licenseData.customer.billing.company_name,
                    }

                    this.$refs['openapi-modal'].show()
                  } else {
                    //risposta positiva nel resto dei casi
                    this.$swal({
                        icon: 'success',
                        title: 'Operazione effettuata correttamente',
                        //title: response.data.reply.reply,
                        //text: 'operazione effettuata correttamente',
                        confirmButtonText: 'chiudi',
                        customClass: {
                        confirmButton: 'btn btn-success',
                        },
                    })
                  }

                  //chiudi sidebar
                  this.$refs.sidebar_azioni.hide();

                  //aggiorna tabella
                  this.loadItems(tipo_licenza);
                    
                } else {
                //risposta negativa (errore sul server)
                  this.$swal({
                      icon: 'error',
                      title: 'Si è verificato un errore sul cambio di stato della licenza id: '+id_licenza,
                      text: 'operazione non riuscita, ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
                      customClass: {
                      confirmButton: 'btn btn-outline-primary',
                      },
                  })
                }
            }).catch(e => {
                console.log(e);
            })
          }
        })
      },
      formattazioneOrario(value, stringa){
        return moment(String(value)).locale('it').format(stringa)
      },
      
    }
  }
  </script>
  
  <style lang="scss" >
  @import '@core/scss/vue/libs/vue-good-table.scss';
  @import '@core/scss/vue/libs/vue-sweetalert.scss';
  
  .riga_sidebar{
    padding-bottom: 5px;
  }
  .btn-alto-dx{
    position: absolute;
    right: 20px;
    top: 20px;
  }

  .spacer-dettagli{
    width: 90px;
    display: inline-block;
  }
  </style>
  